// 
// ecommerce.scss
//

// product

.search-box{
    .form-control{
        border-radius: 30px;
        padding-left: 40px;
    }
    .search-icon{
        font-size: 16px;    
        position: absolute;
        left: 13px;
        top: 0;
        line-height: 38px;
    }
}

.ecommerce {
    .accordion-item {
        .accordion-collapse {
            border: 0;
        }
    }
    button {
        border: 0;
        display: block;
        color: $dark;
        font-weight: $font-weight-medium;
        padding: 8px 16px;    
    }
    button[data-bs-toggle=collapse].collapsed::after {
        content: '\F0415';
    }
    button[data-bs-toggle=collapse]::after {
        content: '\F0374';
        display: block;
        font-family: 'Material Design Icons';
        font-size: 16px;
        position: absolute;
        right: 20px;
        font-weight: $font-weight-medium;
        top: 50%;
        background-image: none;
        transform: translateY(-50%);
    }
    .accordion-body {
        padding: 8px 0;
        border: 0;

        li {
            a {
                display: block;
                padding: 4px 16px;
                color: $body-color;
            }

            &.active {
                a {
                    color: $primary;
                }
            }
        }
    }

}


// Product Details

.product-detai-imgs{
    .nav{
        .nav-link{
            margin: 7px 0px;

            &.active{
                background-color: $gray-300;
            }
        }
    }
}

.product-color{
    a{
        display: inline-block;
        text-align: center;
        color: $body-color;

        .product-color-item{
            margin: 7px;
            border: 2px solid $border-color;
            border-radius: 4px;
        }
        &.active, &:hover{
            color: $primary;
            .product-color-item{
                border-color: $primary !important;
            }
        }
    }
}

.ecommerce-sortby-list{
    li{
        color: $dark;
        a{
            color: $body-color;
            padding: 4px;
        }

        &.active{
            a{
                color: $primary;
            }
        }
    }
}

.product-box{
    padding: 24px;
    border: 1px solid $border-color;
    transition: all 0.4s;
    
    &:hover{
        box-shadow: $box-shadow;
    }

    .product-ribbon {
        position: absolute;
        top: 5px;
        left: 0px;
        padding: 6px 8px;
    }
    .product-like{
        position: absolute;
        top: 0;
        right: 0;  
        z-index: 2;

    a{
        i{
            color: $gray-500;
            position: relative;
            &:hover{
              &:before{
                  content: "\F02D1";
                  color: $danger;
              }
            }
           }
    }
    }
    .product-img{
        position: relative;
        img{
            transition: all 0.4s ease-in-out;
    
            &:hover{
               transform: scale(1.1);
            }
        }
    }
}

.product-detail{
    .nav-pills{
        .nav-link{
            margin-bottom: 7px;
            &.active{
                background-color: $gray-300;
            }
            .tab-img{
                width: 5rem;
            }
        }
    }

    .product-img{
        border: 1px solid $border-color;
        padding: 24px;
    }
}

.product-desc-list{
    li{
        padding: 4px 0px;
    }
}

.product-review-link{
    .list-inline-item{
        a{
            color: $gray-600;
        }
        &:not(:last-child){
            margin-right: 14px;
        }
    }           
}

//Product-Color
.form-check-custom{
    .form-check-input[type=radio] {
        border-radius: 4px;
        background-image: none;
        cursor: pointer;
        border: 2px solid;
    }

    @each $color, $value in $theme-colors {
        &.form-check-#{$color}{
            .form-check-input{
                background-color: rgba(($value), 0.25);
                border-color: $value;
                &:checked{
                    background-color: $value;
                }
           }
        }
    }
}

// Product-Size
.choose-size{
    li{
        &:hover{
            color: $white;
            background-color: $primary;
        } 
    }
}
// Product-Rating
.progressbar{
    border-radius: 0px!important;
}

.choose-range{
    .form-control{
        border-radius: 0px!important;
    }
    i{
        position: relative;
        top: -32px;
    }
}

// ecommerce cart
.product-cart-touchspin{
    border: 1px solid $input-border-color;
    background-color: $input-bg;
    border-radius: $border-radius;
    .form-control{
        border-color: transparent;
        height: 32px
    }
    
    .input-group-btn .btn{
        background-color: transparent !important;
        border-color: transparent !important;
        color: $primary !important;
        font-size: 16px;
        padding: 3px 12px;
        box-shadow: none;
    }

}

//ecommerce order
.table-topbar{
    background-color: $header-dark-bg;
    color: $white;
}

// ecommerce checkout

.shipping-address{
    box-shadow: none;
    &.active{
        border-color: $primary !important;
    }
}

// customer - model
.image-choose{
    display: inline-block;
}