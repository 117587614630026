// 
// Extras pages.scss
//


// pricing

.pricing-nav-tabs{
    display: inline-block;
    background-color: $card-bg;
    box-shadow: $box-shadow;
    padding: 4px;
    border-radius: 7px;
    li{
        display: inline-block;
    }
}

.price-box {
    border-radius: 15px;
    border: 1px solid transparent;
    &:hover{
      box-shadow: $box-shadow;
      border: 1px solid $primary;
    }
    .sub-price {
      font-size: 20px;
      font-weight: 500;
    }
    .plan-features{
        li{
            padding: 7px 0px;
        }
    }
}

/*********************
    Faqs
**********************/ 

.faq-nav-tabs{
    .nav-item{
        margin: 0px 8px;
    }
    .nav-link{
        text-align: center;
        margin-bottom: 8px;
        border: 2px solid $border-color;
        color: $body-color;
        .nav-icon{
            font-size: 40px;
            margin-bottom: 8px;
            display: block;
        }

        &.active{
            border-color: $primary;
            background-color: transparent;
            color: $body-color;

            .nav-icon{
                color: $primary;
            }
        }
    }
}

.text-error{
    font-size: 120px;
    @media (max-width: 575.98px) {
        font-size: 86px;
    }
}
.error-img{
    position: relative;
    width: 125px;
    left: 0px;
    right: 0;
    bottom: 15px;

    @media (max-width: 575.98px) {
        width: 80px;
        left: 0px;
        bottom: 10px;
    }
}




  