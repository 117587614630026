// 
// coming-soon.scss
//




 .counter-number {
    font-size: 32px;
    font-weight: $font-weight-semibold;
    text-align: center;
    display: flex;
    span {
        font-size: 16px;
        font-weight: $font-weight-normal;
        display: block;
        padding-top: 5px;
    }
}

.coming-box {
    width: 25%;
    padding: 14px 7px;
    background-color: $card-bg;
    margin: 0px 5px;
    border-radius: $card-inner-border-radius;
    box-shadow: $box-shadow;
}


 .comming-soon-search{
    input {
        padding: 15px 20px;
        width: 100%;
        color: $gray-800;
        border: 1px solid $gray-100;
        outline: none !important;
        padding-right: 180px;
        padding-left: 30px;
        background-color: $card-bg;
        .form-control{
            &:focus{
               border-color: $white;
                
            }
        }
    }
    button {
        position: absolute;
        top: 7px;
        right: 8px;
        outline: none !important;
        padding: 9px 30px;
    }
}

.social-media {
    i{
        width: 40px;
        height: 40px;
        line-height: 36px;
        font-size: 20px;
        color: $gray-400;
        display: inline-block;
        border:2px solid $gray-400;
        border-radius: 10%;
        &:hover{
            color: $primary;
            border:2px solid $primary;
        }
    }
  }
  

  @media (max-width: 991.98px) { 
    .coming-box {
        width: 25%;
    }
 }