
//
// Form editors.scss
//

// Tinymce 

.tox-tinymce {
  border: 1px solid $input-border-color !important;
}

.tox {
  .tox-statusbar {
    border-top: 1px solid $light !important;
  }
    .tox-menubar, .tox-edit-area__iframe, .tox-statusbar{
      background-color: $card-bg !important;
      background: none !important;
    }
    .tox-mbtn{
      color: $gray-700 !important;
        &:hover:not(:disabled):not(.tox-mbtn--active){
            background-color: $light !important;
        }
    }
    .tox-tbtn{
        &:hover{
            background-color: $light !important;
        }
    }

    .tox-toolbar, .tox-toolbar__overflow, .tox-toolbar__primary{
      background: $light !important;
    }

    .tox-toolbar__primary{
      border-top-color: $light !important;
    }

    .tox-tbtn{
      color: $gray-700 !important;
      svg{
        fill: $gray-700 !important;
      }
    }

    .tox-edit-area__iframe{
      background-color: $card-bg !important;
    }
  
    .tox-statusbar a, .tox-statusbar__path-item, .tox-statusbar__wordcount{
      color: $gray-700 !important;
    }

    &:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
      border-right: 1px solid darken($light, 5%) !important;
    } 
}



// x editable

.editable-input{
  .form-control{
    display: inline-block;
  }
}

.editable-buttons{
  margin-left: 7px;
  .editable-cancel{
    margin-left: 7px;
  }
}

//

// .tox{
//     .tox-tiered-menu{
//     .tox-menu{
//         background-color: $card-bg;
//         border: 1px solid $gray-300;
//         .tox-collection__group{
//             background-color: $card-bg;
      
//         }
//         .tox-collection__group-heading{
//             color: $gray-400;
//         }
//             .tox-collection__item-label{
//                 color:$gray-500;
//               }
        
//     }
//     .tox-collection__item-icon{
//         color: $gray-600;
       
//     }
//     .tox-collection__item{
//         &:hover{
//             background-color: $gray-200;
//         }
//     }
   
// }
// }