/* ==============
  Calendar
===================*/

.fc {
  .fc-toolbar {
      h2 {
          font-size: 16px;
          line-height: 30px;
          text-transform: uppercase;
      }

      @media (max-width: 767.98px) {

          .fc-left,
          .fc-right,
          .fc-center {
              float: none;
              display: block;
              text-align: center;
              clear: both;
              margin: 10px 0;
          }

          >*>* {
              float: none;
          }

          .fc-today-button {
              display: none;
          }
      }

      .btn {
          text-transform: capitalize;
      }

  }
}

.fc {
  th.fc-widget-header {
      background: $gray-300;
      color: $gray-700;
      line-height: 20px;
      padding: 10px 0;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
  }
}

.fc-unthemed {

  .fc-content,
  .fc-divider,
  .fc-list-heading td,
  .fc-list-view,
  .fc-popover,
  .fc-row,
  tbody,
  td,
  th,
  thead {
      border-color: $gray-300;
  }

  td.fc-today {
      background: lighten($gray-200, 2%);
  }
}

.fc-button {
  background: $card-bg;
  border-color: $border-color;
  color: $gray-700;
  text-transform: capitalize;
  box-shadow: none;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: $primary;
  color: $white;
  text-shadow: none;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.fc-event,
.fc-event-dot {
  background-color: $primary;
}

.fc-event .fc-content {
  color: $white;
}

.fc-day-header{
  font-weight: $font-weight-medium;
}

.fc-day-grid-event {
  .fc-time{
      font-weight: $font-weight-medium;
  }
}

#external-events {
  .external-event {
      text-align: left !important;
      padding: 8px 16px;
  }
}

//border
.fc td, .fc th{
  border: $table-border-width solid $table-border-color;
}


// RTL
[dir="rtl"] .fc-header-toolbar {
  direction: ltr !important;
}

[dir="rtl"] .fc-toolbar>*>:not(:first-child) {
  margin-left: .75em;
}



/* Activity */
.activity-feed {
  padding: 15px 15px 0 15px;
  list-style: none;

  .feed-item {
    position: relative;
    padding-bottom: 29px;
    padding-left: 30px;
    border-left: 2px solid $gray-300;

    &:last-child {
      border-color: transparent;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: -8px;
      width: 14px;
      height: 14px;
      border-radius: 30px;
      background: $success;
  }
    .date {
      display: block;
      position: relative;
      top: -5px;
      text-transform: uppercase;
      font-size: 13px;
    }
    .activity-text {
      position: relative;
      top: -3px;
    }
  }
}